<script setup>

import {computed, onMounted, ref} from "vue";
import { Head, useForm, Link, router } from '@inertiajs/vue3';

import Layout from '../Layout.vue'
import InputSimple from "../../components/UI/InputSimple.vue";
import SelectCustom from "../../components/UI/SelectCustom.vue";
import TextareaSimple from "../../components/UI/TextareaSimple.vue";
import Modal from "../../components/UI/Modal.vue";
import SelectPhoneCode from "../../components/UI/SelectPhoneCode.vue";

const props = defineProps({
    gdsList: Object,
    dialCodes: Object,
    pccTypes: Object,
    countries: Object,
    currencies: Object,
    resource: Object,
    pcc: Array,
    contacts: Array,
    actions: {
        type: Array,
        default: () => []
    },
});

const resource = props.resource;
const pccTypesList = props.pccTypes;
const resourcePCCList = ref(props.pcc);
const resourceContactsList = ref(props.contacts ?? []);
const newPCCList = ref([]);
const newContactsList = ref([]);
const searchDialString = ref('');
const editStage = ref(false);
const editContactStage = ref(false);
const editPCCId = ref(null);
const editContactId = ref(null);
const editPCCNew = ref(false);
const editContactNew = ref(false);
const showModal = ref(false);
const showModalContacts = ref(false);
const dialCodes = computed(() => {

    const res = props.dialCodes.filter(dialCode => {
            let dialCodeString = dialCode.code.toString();
            let searchDialStringWithoutPlus = searchDialString.value.replace('+', '');
            return dialCode.title.toLowerCase().includes(searchDialString.value.toLowerCase()) || dialCodeString.toLowerCase().includes(searchDialStringWithoutPlus.toLowerCase())
        }

    ).map((item) => {
        return {
            title: item.title,
            id: item.id,
            code: item.code,
            image: item.image
        }
    });

    return res.sort((a, b) => {
        // Элемент с country_id = 3 будет первым
        if (a.title === 'United States') return -1;
        if (b.title === 'United States') return 1;
        return 0;
    });


});
const form = useForm({
    title: resource.title,
    pcc: [],
    contacts: [],
    pcc_delete: [],
    contacts_delete: [],
    pcc_exist: true,
    resource_id: resource.id,
    exit: false,
    net_issuing_business_ow: resource.net_issuing_business_ow ?? null,
    net_issuing_business_rt: resource.net_issuing_business_rt ?? null,
    net_issuing_first_ow: resource.net_issuing_first_ow ?? null,
    net_issuing_first_rt: resource.net_issuing_first_rt ?? null,
    net_issuing_premium_ow: resource.net_issuing_premium_ow ?? null,
    net_issuing_premium_rt: resource.net_issuing_premium_rt ?? null,
    net_issuing_economy_ow: resource.net_issuing_economy_ow ?? null,
    net_issuing_economy_rt: resource.net_issuing_economy_rt ?? null,

    net_void_business_ow: resource.net_void_business_ow ?? null,
    net_void_business_rt: resource.net_void_business_rt ?? null,
    net_void_first_ow: resource.net_void_first_ow ?? null,
    net_void_first_rt: resource.net_void_first_rt ?? null,
    net_void_premium_ow: resource.net_void_premium_ow ?? null,
    net_void_premium_rt: resource.net_void_premium_rt ?? null,
    net_void_economy_ow: resource.net_void_economy_ow ?? null,
    net_void_economy_rt: resource.net_void_economy_rt ?? null,

    net_exchange_business_ow: resource.net_exchange_business_ow ?? null,
    net_exchange_business_rt: resource.net_exchange_business_rt ?? null,
    net_exchange_first_ow: resource.net_exchange_first_ow ?? null,
    net_exchange_first_rt: resource.net_exchange_first_rt ?? null,
    net_exchange_premium_ow: resource.net_exchange_premium_ow ?? null,
    net_exchange_premium_rt: resource.net_exchange_premium_rt ?? null,
    net_exchange_economy_ow: resource.net_exchange_economy_ow ?? null,
    net_exchange_economy_rt: resource.net_exchange_economy_rt ?? null,

    net_refund_business_ow: resource.net_refund_business_ow ?? null,
    net_refund_business_rt: resource.net_refund_business_rt ?? null,
    net_refund_first_ow: resource.net_refund_first_ow ?? null,
    net_refund_first_rt: resource.net_refund_first_rt ?? null,
    net_refund_premium_ow: resource.net_refund_premium_ow ?? null,
    net_refund_premium_rt: resource.net_refund_premium_rt ?? null,
    net_refund_economy_ow: resource.net_refund_economy_ow ?? null,
    net_refund_economy_rt: resource.net_refund_economy_rt ?? null,

    pub_issuing_business_ow: resource.pub_issuing_business_ow ?? null,
    pub_issuing_business_rt: resource.pub_issuing_business_rt ?? null,
    pub_issuing_first_ow: resource.pub_issuing_first_ow ?? null,
    pub_issuing_first_rt: resource.pub_issuing_first_rt ?? null,
    pub_issuing_premium_ow: resource.pub_issuing_premium_ow ?? null,
    pub_issuing_premium_rt: resource.pub_issuing_premium_rt ?? null,
    pub_issuing_economy_ow: resource.pub_issuing_economy_ow ?? null,
    pub_issuing_economy_rt: resource.pub_issuing_economy_rt ?? null,

    pub_void_business_ow: resource.pub_void_business_ow ?? null,
    pub_void_business_rt: resource.pub_void_business_rt ?? null,
    pub_void_first_ow: resource.pub_void_first_ow ?? null,
    pub_void_first_rt: resource.pub_void_first_rt ?? null,
    pub_void_premium_ow: resource.pub_void_premium_ow ?? null,
    pub_void_premium_rt: resource.pub_void_premium_rt ?? null,
    pub_void_economy_ow: resource.pub_void_economy_ow ?? null,
    pub_void_economy_rt: resource.pub_void_economy_rt ?? null,

    pub_exchange_business_ow: resource.pub_exchange_business_ow ?? null,
    pub_exchange_business_rt: resource.pub_exchange_business_rt ?? null,
    pub_exchange_first_ow: resource.pub_exchange_first_ow ?? null,
    pub_exchange_first_rt: resource.pub_exchange_first_rt ?? null,
    pub_exchange_premium_ow: resource.pub_exchange_premium_ow ?? null,
    pub_exchange_premium_rt: resource.pub_exchange_premium_rt ?? null,
    pub_exchange_economy_ow: resource.pub_exchange_economy_ow ?? null,
    pub_exchange_economy_rt: resource.pub_exchange_economy_rt ?? null,

    pub_refund_business_ow: resource.pub_refund_business_ow ?? null,
    pub_refund_business_rt: resource.pub_refund_business_rt ?? null,
    pub_refund_first_ow: resource.pub_refund_first_ow ?? null,
    pub_refund_first_rt: resource.pub_refund_first_rt ?? null,
    pub_refund_premium_ow: resource.pub_refund_premium_ow ?? null,
    pub_refund_premium_rt: resource.pub_refund_premium_rt ?? null,
    pub_refund_economy_ow: resource.pub_refund_economy_ow ?? null,
    pub_refund_economy_rt: resource.pub_refund_economy_rt ?? null,

    tour_issuing_business_ow: resource.tour_issuing_business_ow ?? null,
    tour_issuing_business_rt: resource.tour_issuing_business_rt ?? null,
    tour_issuing_first_ow: resource.tour_issuing_first_ow ?? null,
    tour_issuing_first_rt: resource.tour_issuing_first_rt ?? null,
    tour_issuing_premium_ow: resource.tour_issuing_premium_ow ?? null,
    tour_issuing_premium_rt: resource.tour_issuing_premium_rt ?? null,
    tour_issuing_economy_ow: resource.tour_issuing_economy_ow ?? null,
    tour_issuing_economy_rt: resource.tour_issuing_economy_rt ?? null,

    tour_void_business_ow: resource.tour_void_business_ow ?? null,
    tour_void_business_rt: resource.tour_void_business_rt ?? null,
    tour_void_first_ow: resource.tour_void_first_ow ?? null,
    tour_void_first_rt: resource.tour_void_first_rt ?? null,
    tour_void_premium_ow: resource.tour_void_premium_ow ?? null,
    tour_void_premium_rt: resource.tour_void_premium_rt ?? null,
    tour_void_economy_ow: resource.tour_void_economy_ow ?? null,
    tour_void_economy_rt: resource.tour_void_economy_rt ?? null,

    tour_exchange_business_ow: resource.tour_exchange_business_ow ?? null,
    tour_exchange_business_rt: resource.tour_exchange_business_rt ?? null,
    tour_exchange_first_ow: resource.tour_exchange_first_ow ?? null,
    tour_exchange_first_rt: resource.tour_exchange_first_rt ?? null,
    tour_exchange_premium_ow: resource.tour_exchange_premium_ow ?? null,
    tour_exchange_premium_rt: resource.tour_exchange_premium_rt ?? null,
    tour_exchange_economy_ow: resource.tour_exchange_economy_ow ?? null,
    tour_exchange_economy_rt: resource.tour_exchange_economy_rt ?? null,

    tour_refund_business_ow: resource.tour_refund_business_ow ?? null,
    tour_refund_business_rt: resource.tour_refund_business_rt ?? null,
    tour_refund_first_ow: resource.tour_refund_first_ow ?? null,
    tour_refund_first_rt: resource.tour_refund_first_rt ?? null,
    tour_refund_premium_ow: resource.tour_refund_premium_ow ?? null,
    tour_refund_premium_rt: resource.tour_refund_premium_rt ?? null,
    tour_refund_economy_ow: resource.tour_refund_economy_ow ?? null,
    tour_refund_economy_rt: resource.tour_refund_economy_rt ?? null,

    sea_issuing_business_ow: resource.sea_issuing_business_ow ?? null,
    sea_issuing_business_rt: resource.sea_issuing_business_rt ?? null,
    sea_issuing_first_ow: resource.sea_issuing_first_ow ?? null,
    sea_issuing_first_rt: resource.sea_issuing_first_rt ?? null,
    sea_issuing_premium_ow: resource.sea_issuing_premium_ow ?? null,
    sea_issuing_premium_rt: resource.sea_issuing_premium_rt ?? null,
    sea_issuing_economy_ow: resource.sea_issuing_economy_ow ?? null,
    sea_issuing_economy_rt: resource.sea_issuing_economy_rt ?? null,

    sea_void_business_ow: resource.sea_void_business_ow ?? null,
    sea_void_business_rt: resource.sea_void_business_rt ?? null,
    sea_void_first_ow: resource.sea_void_first_ow ?? null,
    sea_void_first_rt: resource.sea_void_first_rt ?? null,
    sea_void_premium_ow: resource.sea_void_premium_ow ?? null,
    sea_void_premium_rt: resource.sea_void_premium_rt ?? null,
    sea_void_economy_ow: resource.sea_void_economy_ow ?? null,
    sea_void_economy_rt: resource.sea_void_economy_rt ?? null,

    sea_exchange_business_ow: resource.sea_exchange_business_ow ?? null,
    sea_exchange_business_rt: resource.sea_exchange_business_rt ?? null,
    sea_exchange_first_ow: resource.sea_exchange_first_ow ?? null,
    sea_exchange_first_rt: resource.sea_exchange_first_rt ?? null,
    sea_exchange_premium_ow: resource.sea_exchange_premium_ow ?? null,
    sea_exchange_premium_rt: resource.sea_exchange_premium_rt ?? null,
    sea_exchange_economy_ow: resource.sea_exchange_economy_ow ?? null,
    sea_exchange_economy_rt: resource.sea_exchange_economy_rt ?? null,

    sea_refund_business_ow: resource.sea_refund_business_ow ?? null,
    sea_refund_business_rt: resource.sea_refund_business_rt ?? null,
    sea_refund_first_ow: resource.sea_refund_first_ow ?? null,
    sea_refund_first_rt: resource.sea_refund_first_rt ?? null,
    sea_refund_premium_ow: resource.sea_refund_premium_ow ?? null,
    sea_refund_premium_rt: resource.sea_refund_premium_rt ?? null,
    sea_refund_economy_ow: resource.sea_refund_economy_ow ?? null,
    sea_refund_economy_rt: resource.sea_refund_economy_rt ?? null,
})
const formContacts  = useForm ({
    dial_code_id: dialCodes.value[0],
    id: null,
    id_tmp: null,
    person : '',
    department : '',
    phone : '',
    email : '',
    description : '',
})

const formPCC = useForm({
    default_fare_type: '',
    id_tmp: null,
    pcc: '',
    bridge: '',
    gds_id: '',
    country_id: '',
    type_id: '',
    currency_id: '',
    dk_number : '',
    commission_percent: null,
    // business_net_pub_issuing: '',
    // business_net_pub_ref_exch: '',
    // business_tours_issuing: '',
    // business_tours_ref_exch: '',
    // business_void: '',
    // economy_net_pub_issuing: '',
    // economy_net_pub_ref_exch: '',
    // economy_tours_issuing: '',
    // economy_tours_ref_exch: '',
    // economy_void: '',
    // premium_net_pub_issuing: '',
    // premium_net_pub_ref_exch: '',
    // premium_tours_issuing: '',
    // premium_tours_ref_exch: '',
    // premium_void: '',
    notes: '',
});
// const formFareTypeFee = useForm({
//     net_issuing_business_ow: resource.net_issuing_business_ow ?? '',
//     net_issuing_business_rt: resource.net_issuing_business_rt ?? '',
//     net_issuing_first_ow: resource.net_issuing_first_ow ?? '',
//     net_issuing_first_rt: resource.net_issuing_first_rt ?? '',
//     net_issuing_premium_ow: resource.net_issuing_premium_ow ?? '',
//     net_issuing_premium_rt: resource.net_issuing_premium_rt ?? '',
//     net_issuing_economy_ow: resource.net_issuing_economy_ow ?? '',
//     net_issuing_economy_rt: resource.net_issuing_economy_rt ?? '',
//
//     net_void_business_ow: resource.net_void_business_ow ?? '',
//     net_void_business_rt: resource.net_void_business_rt ?? '',
//     net_void_first_ow: resource.net_void_first_ow ?? '',
//     net_void_first_rt: resource.net_void_first_rt ?? '',
//     net_void_premium_ow: resource.net_void_premium_ow ?? '',
//     net_void_premium_rt: resource.net_void_premium_rt ?? '',
//     net_void_economy_ow: resource.net_void_economy_ow ?? '',
//     net_void_economy_rt: resource.net_void_economy_rt ?? '',
//
//     net_exchange_business_ow: resource.net_exchange_business_ow ?? '',
//     net_exchange_business_rt: resource.net_exchange_business_rt ?? '',
//     net_exchange_first_ow: resource.net_exchange_first_ow ?? '',
//     net_exchange_first_rt: resource.net_exchange_first_rt ?? '',
//     net_exchange_premium_ow: resource.net_exchange_premium_ow ?? '',
//     net_exchange_premium_rt: resource.net_exchange_premium_rt ?? '',
//     net_exchange_economy_ow: resource.net_exchange_economy_ow ?? '',
//     net_exchange_economy_rt: resource.net_exchange_economy_rt ?? '',
//
//     net_refund_business_ow: resource.net_refund_business_ow ?? '',
//     net_refund_business_rt: resource.net_refund_business_rt ?? '',
//     net_refund_first_ow: resource.net_refund_first_ow ?? '',
//     net_refund_first_rt: resource.net_refund_first_rt ?? '',
//     net_refund_premium_ow: resource.net_refund_premium_ow ?? '',
//     net_refund_premium_rt: resource.net_refund_premium_rt ?? '',
//     net_refund_economy_ow: resource.net_refund_economy_ow ?? '',
//     net_refund_economy_rt: resource.net_refund_economy_rt ?? '',
//
//     pub_issuing_business_ow: resource.pub_issuing_business_ow ?? '',
//     pub_issuing_business_rt: resource.pub_issuing_business_rt ?? '',
//     pub_issuing_first_ow: resource.pub_issuing_first_ow ?? '',
//     pub_issuing_first_rt: resource.pub_issuing_first_rt ?? '',
//     pub_issuing_premium_ow: resource.pub_issuing_premium_ow ?? '',
//     pub_issuing_premium_rt: resource.pub_issuing_premium_rt ?? '',
//     pub_issuing_economy_ow: resource.pub_issuing_economy_ow ?? '',
//     pub_issuing_economy_rt: resource.pub_issuing_economy_rt ?? '',
//
//     pub_void_business_ow: resource.pub_void_business_ow ?? '',
//     pub_void_business_rt: resource.pub_void_business_rt ?? '',
//     pub_void_first_ow: resource.pub_void_first_ow ?? '',
//     pub_void_first_rt: resource.pub_void_first_rt ?? '',
//     pub_void_premium_ow: resource.pub_void_premium_ow ?? '',
//     pub_void_premium_rt: resource.pub_void_premium_rt ?? '',
//     pub_void_economy_ow: resource.pub_void_economy_ow ?? '',
//     pub_void_economy_rt: resource.pub_void_economy_rt ?? '',
//
//     pub_exchange_business_ow: resource.pub_exchange_business_ow ?? '',
//     pub_exchange_business_rt: resource.pub_exchange_business_rt ?? '',
//     pub_exchange_first_ow: resource.pub_exchange_first_ow ?? '',
//     pub_exchange_first_rt: resource.pub_exchange_first_rt ?? '',
//     pub_exchange_premium_ow: resource.pub_exchange_premium_ow ?? '',
//     pub_exchange_premium_rt: resource.pub_exchange_premium_rt ?? '',
//     pub_exchange_economy_ow: resource.pub_exchange_economy_ow ?? '',
//     pub_exchange_economy_rt: resource.pub_exchange_economy_rt ?? '',
//
//     pub_refund_business_ow: resource.pub_refund_business_ow ?? '',
//     pub_refund_business_rt: resource.pub_refund_business_rt ?? '',
//     pub_refund_first_ow: resource.pub_refund_first_ow ?? '',
//     pub_refund_first_rt: resource.pub_refund_first_rt ?? '',
//     pub_refund_premium_ow: resource.pub_refund_premium_ow ?? '',
//     pub_refund_premium_rt: resource.pub_refund_premium_rt ?? '',
//     pub_refund_economy_ow: resource.pub_refund_economy_ow ?? '',
//     pub_refund_economy_rt: resource.pub_refund_economy_rt ?? '',
//
//     tour_issuing_business_ow: resource.tour_issuing_business_ow ?? '',
//     tour_issuing_business_rt: resource.tour_issuing_business_rt ?? '',
//     tour_issuing_first_ow: resource.tour_issuing_first_ow ?? '',
//     tour_issuing_first_rt: resource.tour_issuing_first_rt ?? '',
//     tour_issuing_premium_ow: resource.tour_issuing_premium_ow ?? '',
//     tour_issuing_premium_rt: resource.tour_issuing_premium_rt ?? '',
//     tour_issuing_economy_ow: resource.tour_issuing_economy_ow ?? '',
//     tour_issuing_economy_rt: resource.tour_issuing_economy_rt ?? '',
//
//     tour_void_business_ow: resource.tour_void_business_ow ?? '',
//     tour_void_business_rt: resource.tour_void_business_rt ?? '',
//     tour_void_first_ow: resource.tour_void_first_ow ?? '',
//     tour_void_first_rt: resource.tour_void_first_rt ?? '',
//     tour_void_premium_ow: resource.tour_void_premium_ow ?? '',
//     tour_void_premium_rt: resource.tour_void_premium_rt ?? '',
//     tour_void_economy_ow: resource.tour_void_economy_ow ?? '',
//     tour_void_economy_rt: resource.tour_void_economy_rt ?? '',
//
//     tour_exchange_business_ow: resource.tour_exchange_business_ow ?? '',
//     tour_exchange_business_rt: resource.tour_exchange_business_rt ?? '',
//     tour_exchange_first_ow: resource.tour_exchange_first_ow ?? '',
//     tour_exchange_first_rt: resource.tour_exchange_first_rt ?? '',
//     tour_exchange_premium_ow: resource.tour_exchange_premium_ow ?? '',
//     tour_exchange_premium_rt: resource.tour_exchange_premium_rt ?? '',
//     tour_exchange_economy_ow: resource.tour_exchange_economy_ow ?? '',
//     tour_exchange_economy_rt: resource.tour_exchange_economy_rt ?? '',
//
//     tour_refund_business_ow: resource.tour_refund_business_ow ?? '',
//     tour_refund_business_rt: resource.tour_refund_business_rt ?? '',
//     tour_refund_first_ow: resource.tour_refund_first_ow ?? '',
//     tour_refund_first_rt: resource.tour_refund_first_rt ?? '',
//     tour_refund_premium_ow: resource.tour_refund_premium_ow ?? '',
//     tour_refund_premium_rt: resource.tour_refund_premium_rt ?? '',
//     tour_refund_economy_ow: resource.tour_refund_economy_ow ?? '',
//     tour_refund_economy_rt: resource.tour_refund_economy_rt ?? '',
//
//     sea_issuing_business_ow: resource.sea_issuing_business_ow ?? '',
//     sea_issuing_business_rt: resource.sea_issuing_business_rt ?? '',
//     sea_issuing_first_ow: resource.sea_issuing_first_ow ?? '',
//     sea_issuing_first_rt: resource.sea_issuing_first_rt ?? '',
//     sea_issuing_premium_ow: resource.sea_issuing_premium_ow ?? '',
//     sea_issuing_premium_rt: resource.sea_issuing_premium_rt ?? '',
//     sea_issuing_economy_ow: resource.sea_issuing_economy_ow ?? '',
//     sea_issuing_economy_rt: resource.sea_issuing_economy_rt ?? '',
//
//     sea_void_business_ow: resource.sea_void_business_ow ?? '',
//     sea_void_business_rt: resource.sea_void_business_rt ?? '',
//     sea_void_first_ow: resource.sea_void_first_ow ?? '',
//     sea_void_first_rt: resource.sea_void_first_rt ?? '',
//     sea_void_premium_ow: resource.sea_void_premium_ow ?? '',
//     sea_void_premium_rt: resource.sea_void_premium_rt ?? '',
//     sea_void_economy_ow: resource.sea_void_economy_ow ?? '',
//     sea_void_economy_rt: resource.sea_void_economy_rt ?? '',
//
//     sea_exchange_business_ow: resource.sea_exchange_business_ow ?? '',
//     sea_exchange_business_rt: resource.sea_exchange_business_rt ?? '',
//     sea_exchange_first_ow: resource.sea_exchange_first_ow ?? '',
//     sea_exchange_first_rt: resource.sea_exchange_first_rt ?? '',
//     sea_exchange_premium_ow: resource.sea_exchange_premium_ow ?? '',
//     sea_exchange_premium_rt: resource.sea_exchange_premium_rt ?? '',
//     sea_exchange_economy_ow: resource.sea_exchange_economy_ow ?? '',
//     sea_exchange_economy_rt: resource.sea_exchange_economy_rt ?? '',
//
//     sea_refund_business_ow: resource.sea_refund_business_ow ?? '',
//     sea_refund_business_rt: resource.sea_refund_business_rt ?? '',
//     sea_refund_first_ow: resource.sea_refund_first_ow ?? '',
//     sea_refund_first_rt: resource.sea_refund_first_rt ?? '',
//     sea_refund_premium_ow: resource.sea_refund_premium_ow ?? '',
//     sea_refund_premium_rt: resource.sea_refund_premium_rt ?? '',
//     sea_refund_economy_ow: resource.sea_refund_economy_ow ?? '',
//     sea_refund_economy_rt: resource.sea_refund_economy_rt ?? '',
// });

const searchCountryString = ref('');
const searchCurrencyString = ref('');

const searchCountry = (value) => {
    searchCountryString.value = value;
}

const searchCurrency = (value) => {
    searchCurrencyString.value = value;
}

const handleInput = (id, key) => {
    form[key] = id;
}

const handleSelect = (option, key) => {
    formPCC[key] = option;
}

const handleSelectFormContacts = (option, key) => {
    formContacts[key] = option;
}

const showModalChange = (stage) => {
    showModal.value = !showModal.value;

    const body = document.querySelector('body');
    const scrollbarWidth = document.body.offsetWidth - document.body.clientWidth;

    if (stage == 'new') {
        formPCC.reset();
        editStage.value = false;
    }

    if (showModal.value) {
        body.classList.add('modal__open');
        body.style.overflow = 'hidden';
        body.style.paddingRight = scrollbarWidth + 'px';
    } else {
        body.classList.remove('modal__open');
        body.removeAttribute('style');
    }

    const tooltips = document.querySelectorAll('.tooltip');
    tooltips.forEach((tooltip) => {
        tooltip.remove();
    })
}

const showModalContactsChange = (stage) => {
    showModalContacts.value = !showModalContacts.value;
    const body = document.querySelector('body');
    const scrollbarWidth = document.body.offsetWidth - document.body.clientWidth;
    if (stage === 'new') {
        formContacts.reset();
        formContacts.dial_code_id = dialCodes.value[0];
        editStage.value = false;
    }

    if (showModalContacts.value) {
        body.classList.add('modal__open');
        body.style.overflow = 'hidden';
        body.style.paddingRight = scrollbarWidth + 'px';
    } else {
        body.classList.remove('modal__open');
        body.removeAttribute('style');
    }

    const tooltips = document.querySelectorAll('.tooltip');
    tooltips.forEach((tooltip) => {
        tooltip.remove();
    })
}
const addPCCValue = (pcc) => {

    if (editPCCNew.value) {
        formPCC.id_tmp = pcc.id_tmp;
    } else {
        formPCC.id = pcc.id;
    }
    formPCC.dk_number = pcc.dk_number;
    formPCC.pcc = pcc.pcc;
    formPCC.bridge = pcc.bridge;
    formPCC.default_fare_type = pcc.default_fare_type;
    formPCC.gds_id = findItem(pcc.gds_id, props.gdsList);
    formPCC.country_id = findItem(pcc.country_id, props.countries);
    formPCC.type_id = findItem(pcc.type_id, props.pccTypes);
    formPCC.currency_id = findItem(pcc.currency_id, props.currencies);
    formPCC.business_net_pub_issuing = pcc.business_net_pub_issuing;
    formPCC.business_net_pub_ref_exch = pcc.business_net_pub_ref_exch;
    formPCC.business_tours_issuing = pcc.business_tours_issuing;
    formPCC.business_tours_ref_exch = pcc.business_tours_ref_exch;
    formPCC.business_void = pcc.business_void;
    formPCC.economy_net_pub_issuing = pcc.economy_net_pub_issuing;
    formPCC.economy_net_pub_ref_exch = pcc.economy_net_pub_ref_exch;
    formPCC.economy_tours_issuing = pcc.economy_tours_issuing;
    formPCC.economy_tours_ref_exch = pcc.economy_tours_ref_exch;
    formPCC.economy_void = pcc.economy_void;
    formPCC.premium_net_pub_issuing = pcc.premium_net_pub_issuing;
    formPCC.premium_net_pub_ref_exch = pcc.premium_net_pub_ref_exch;
    formPCC.premium_tours_issuing = pcc.premium_tours_issuing;
    formPCC.premium_tours_ref_exch = pcc.premium_tours_ref_exch;
    formPCC.premium_void = pcc.premium_void;
    formPCC.notes = pcc.notes;
    formPCC.commission_percent = pcc.commission_percent;

}

const addContactsValue = (contact) => {

    if (editContactNew.value) {
        formContacts.id_tmp = contact.id_tmp;
    } else {
        formContacts.id = contact.id;
    }
    formContacts.dial_code_id = !(Object.prototype.toString.call(contact.dial_code_id) === '[object Object]') ? dialCodes.value.find(i => i.id === contact.dial_code_id) : contact.dial_code_id;
    formContacts.person = contact.person;
    formContacts.department = contact.department;
    formContacts.description = contact.description;
    formContacts.phone = contact.phone;
    formContacts.email = contact.email;
}

const editPCC = (id) => {

    showModalChange();
    const pcc = resourcePCCList.value.find((item) => item.id == id);
    editPCCId.value = id;
    editStage.value = true;
    addPCCValue(pcc);
}

const editContact = (id) => {

    showModalContactsChange();

    const contact = resourceContactsList.value.find((item) => item.id === id);
    editContactId.value = id;
    editContactStage.value = true;
    addContactsValue(contact);
}

const editNewPCC = (id) => {
    showModalChange();
    const pcc = newPCCList.value.find((item) => item.id_tmp == id);
    editPCCId.value = id;
    editStage.value = true;
    editPCCNew.value = true;
    addPCCValue(pcc);
}

const editNewContact = (id) => {
    showModalContactsChange();
    const contact = newContactsList.value.find((item) => item.id_tmp === id);
    editContactId.value = id;
    editContactStage.value = true;
    editContactNew.value = true;
    addContactsValue(contact);
}

const findItemTitle = (item, array) => {
    const id = item?.id ? item.id : item;
    return array.find((item) => item.id == id).title;
}

const findCurrencySymbol = (item, array) => {
    const id = item?.id ? item.id : item;
    return array.find((item) => item.id == id)?.symbol;
}

const findItem = (item, array) => {
    const id = item?.id ? item.id : item;
    return array.find((item) => item.id == id);
}

const countriesList = computed(() => {
    return props.countries.filter(country => country.title.toLowerCase().includes(searchCountryString.value.toLowerCase())).map((item) => {
        return {
            title: item.title,
            id: item.id
        }
    })
});

const currencyList = computed(() => {
    return props.currencies.filter(currency => currency.title.toLowerCase().includes(searchCurrencyString.value.toLowerCase())).map((item) => {
        return {
            title: item.title,
            id: item.id
        }
    })
});

function isPlainObject(value) {
    return Object.prototype.toString.call(value) === '[object Object]';
}

const submitForm = (exit) => {
    console.log('form')
    form.exit = !!exit;
    form
        .transform((data) => {
            data.pcc.forEach((item, index) => {
                item.gds_id = item.gds_id && item.gds_id.id ? item.gds_id.id : item.gds_id;
                item.country_id = item.country_id && item.country_id.id ? item.country_id.id : item.country_id;
                item.type_id = item.type_id && item.type_id.id ? item.type_id.id : item.type_id;
                item.currency_id = item.currency_id && item.currency_id.id ? item.currency_id.id : item.currency_id;
                item.default_fare_type = item.default_fare_type ?? null;
            })
            data.contacts.forEach((item, index) => {
                console.log('item', item);
                if (isPlainObject(item.dial_code_id)) {
                    item.dial_code_id = item.dial_code_id && item.dial_code_id.id ? item.dial_code_id.id : null;
                }
            })
            return data;
        })
        .put('/admin/consolidators/update/' + resource.id, {
            preserveScroll: true,
            // preserveState: false,
            onSuccess: () => {
                router.reload({ only: ['form'] }) // при сохранении без выходя обновляем данные в форме
                if (form.contacts.length > 0) {form.contacts = [];}
            },
        });
}


let tmpId = 1;
let tmpContactsId = 1;

const submitFormPCC = () => {
    formPCC
        .transform((data) => ({
            ...data,
            gds_id: data.gds_id && data.gds_id.id ? data.gds_id.id : data.gds_id,
            country_id: data.country_id && data.country_id.id ? data.country_id.id : data.country_id,
            type_id: data.type_id && data.type_id.id ? data.type_id.id : data.type_id,
            currency_id: data.currency_id && data.currency_id.id ? data.currency_id.id : data.currency_id
        }))
        .post('/admin/consolidator-pcc/validate', {
            preserveScroll: true,
            onSuccess: () => {
                if (editStage.value) {
                    if (editPCCNew.value) {

                        let tmpPCC = newPCCList.value.find(item => item.id_tmp === editPCCId.value);
                        Object.keys(tmpPCC).forEach(key => {
                            if (formPCC[key]) {
                                tmpPCC[key] = formPCC[key];
                            }
                        })
                        let tmpInFormPCC = form.pcc.find(item => item.id_tmp === editPCCId.value);

                        if (tmpInFormPCC) {
                            Object.keys(tmpInFormPCC).forEach(key => {
                                if (formPCC[key]) {
                                    tmpInFormPCC[key] = formPCC[key];
                                }
                            })
                        } else {
                            form.pcc.push(tmpPCC);
                        }

                    } else {

                        let tmpPCC = resourcePCCList.value.find(item => item.id === editPCCId.value);
                        Object.keys(tmpPCC).forEach(key => {
                            if (formPCC[key]) {
                                tmpPCC[key] = formPCC[key];
                            }
                            if (key === 'dk_number') {
                                tmpPCC[key] = formPCC[key] === '' ? null : formPCC.dk_number;
                            }
                            if (key === 'notes') {
                                tmpPCC[key] = formPCC[key] === '' ? null : formPCC.notes;
                            }
                        })

                        tmpPCC.country.id = tmpPCC.country_id.id;
                        tmpPCC.country.title = tmpPCC.country_id.title;

                        tmpPCC.currency.id = tmpPCC.currency_id.id;
                        tmpPCC.currency.title = tmpPCC.currency_id.title;

                        tmpPCC.gds.id = tmpPCC.gds_id.id;
                        tmpPCC.gds.title = tmpPCC.gds_id.title;

                        tmpPCC.type.id = tmpPCC.type_id.id;
                        tmpPCC.type.title = tmpPCC.type_id.title;

                        let tmpInFormPCC = form.pcc.find(item => item.id === editPCCId.value);

                        if (tmpInFormPCC) {
                            Object.keys(tmpInFormPCC).forEach(key => {
                                if (formPCC[key]) {
                                    tmpInFormPCC[key] = formPCC[key];
                                }
                                if (key === 'dk_number') {
                                    tmpPCC[key] = formPCC[key] === '' ? null : formPCC.dk_number;
                                }
                                if (key === 'notes') {
                                    tmpPCC[key] = formPCC[key] === '' ? null : formPCC.notes;
                                }
                            })
                        } else {
                            form.pcc.push(tmpPCC);
                        }
                    }
                    showModalChange();
                    formPCC.reset();
                } else {
                    formPCC.id_tmp = tmpId++;
                    form.pcc.push(formPCC.data());
                    newPCCList.value.push(formPCC.data());
                    showModalChange();
                    formPCC.reset();
                }
            }
        })
}


const submitFormContacts = () => {
    formContacts
        .transform((data) => ({
            ...data,
            dial_code_id : data.dial_code_id.id ?? null,
            description : (data.description  === '' || data.description === null) ? null : data.description
        }))
        .post('/admin/consolidator-contacts/validate', {
            preserveScroll: true,
            onSuccess: () => {
                if (editContactStage.value) {
                    if (editContactNew.value) {

                        let tmpContact = newContactsList.value.find(item => item.id_tmp === editContactId.value);
                        Object.keys(tmpContact).forEach(key => {
                            if (formContacts[key]) {
                                tmpContact[key] = formContacts[key];
                            }
                        })
                        let tmpInFormContacts = form.contacts.find(item => item.id_tmp === editContactId.value);

                        if (tmpInFormContacts) {
                            Object.keys(tmpInFormContacts).forEach(key => {
                                if (formContacts[key]) {
                                    tmpInFormContacts[key] = formContacts[key];
                                }
                            })
                        } else {
                            form.pcc.push(tmpContact);
                        }

                    } else {
                        let tmpContact = resourceContactsList.value.find(item => item.id === editContactId.value);
                        Object.keys(tmpContact).forEach(key => {
                                tmpContact[key] = formContacts[key];
                        })
                        let tmpInFormContacts = form.contacts.find(item => item.id === editContactId.value);
                        if (tmpInFormContacts) {
                            Object.keys(tmpInFormContacts).forEach(key => {
                                    tmpInFormContacts[key] = formContacts[key];
                            })
                        } else {
                            form.contacts.push(tmpContact);
                        }
                    }
                    showModalContactsChange();
                    formContacts.reset();
                } else {
                    formContacts.id_tmp = tmpContactsId++;
                    form.contacts.push(formContacts.data());
                    newContactsList.value.push(formContacts.data());
                    showModalContactsChange();
                    formContacts.reset();
                }
            }
        })
}


const deletePCCItem = (pccId) => {
    if(confirm("Do you really want to delete?")){
        form.pcc_delete.push(pccId);
        resourcePCCList.value = resourcePCCList.value.filter(item => item.id != pccId);
        const tooltips = document.querySelectorAll('.tooltip');
        if (tooltips.length == 0) return;
        tooltips.forEach((tooltip) => {
            tooltip.remove();
        })
        if (form.pcc.length == 0 && resourcePCCList.value.length == 0) {
            form.pcc_exist = false;
        }
    }
}

const searchDial = (value) => {
    searchDialString.value = value;
}

const deleteContactItem = (contact) => {
    if(confirm("Do you really want to delete?")){
        form.contacts_delete.push(contact);
        resourceContactsList.value = resourceContactsList.value.filter(item => item.id !== contact);
        const tooltips = document.querySelectorAll('.tooltip');
        if (tooltips.length === 0) return;
        tooltips.forEach((tooltip) => {
            tooltip.remove();
        })
        if (form.contacts.length === 0 && resourceContactsList.value.length === 0) {
            form.contacts_exist = false;
        }
    }
}

const deletePCCNewItem = (pccId) => {
    if(confirm("Do you really want to delete?")){
        form.pcc_delete.push(pccId);
        form.pcc = form.pcc.filter(item => item.id != pccId);
        newPCCList.value = newPCCList.value.filter(item => item.id_tmp != pccId);

        const tooltips = document.querySelectorAll('.tooltip');
        if (tooltips.length == 0) return;
        tooltips.forEach((tooltip) => {
            tooltip.remove();
        })

        if (form.pcc.length == 0 && resourcePCCList.value.length == 0) {
            form.pcc_exist = false;
        }

    }
}

const deleteContactsNewItem = (contactId) => {
    if(confirm("Do you really want to delete?")){
        form.contacts_delete.push(contactId);
        form.contacts = form.contacts.filter(item => item.id !== contactId);
        newContactsList.value = newContactsList.value.filter(item => item.id_tmp !== contactId);
        const tooltips = document.querySelectorAll('.tooltip');
        if (tooltips.length === 0) return;
        tooltips.forEach((tooltip) => {
            tooltip.remove();
        })

        if (form.contacts.length === 0 && resourceContactsList.value.length === 0) {
            form.contacts_exist = false;
        }

    }
}

const deleteItem = () => {
    if(confirm("Do you really want to delete?")){
        router.delete('/admin/consolidators/destroy/' + resource.id, {
            preserveScroll: true,
        })
    }
}


</script>

<template>

    <Head title="Add Consolidator" />

    <Layout>
        <template v-slot:breadcrumb>
            <ol class="breadcrumb breadcrumb-alt">
                <li aria-current="page" class="breadcrumb-item">
                    <Link class="link-fx"
                          href="/admin/consolidators">
                        Consolidators
                    </Link>
                </li>
                <li class="breadcrumb-item">
                    Add consolidator
                </li>
            </ol>
        </template>

        <template v-slot:title>Consolidators</template>

        <template v-slot:content>
            <div class="row justify-content-center">
                <div class="col-xxl-9">
                    <div class="block block-rounded">
                        <!-- Form Start -->
                        <form>
                            <div class="block-header block-header-default">
                                <h3 class="block-title">Add consolidator</h3>
                            </div>
                            <div class="block-content block-content-full">
                                <div class="row g-12">
                                    <div class="col-12" style="display:flex; align-items: center; justify-content: space-between">
                                        <div class="col-6">
                                            <input-simple
                                                label="Consolidator name"
                                                name="title"
                                                required
                                                placeholder="Enter consolidator name"
                                                v-model="form.title"
                                                :hint="form.errors.title"
                                            ></input-simple>
                                        </div>
                                        <div style="display:flex;">
                                            <div>
                                                <button type="button" class="btn btn-sm btn-outline-primary" style="height: 38px; margin-top: 10px; margin-left: 12px" @click="showModalChange('new')">
                                                    <i class="fa fa-fw fa-plus"></i>
                                                    Add PCC</button>
                                            </div>
                                            <div class="">
                                                <button type="button" class="btn btn-sm btn-outline-primary" style="margin-left: 12px; height: 38px; margin-top: 10px" @click="showModalContactsChange('new')">
                                                    <i class="fa fa-fw fa-plus"></i>
                                                    Add Contacts</button>
                                            </div>
                                        </div>

                                    </div>
<!--                                    <div class="col-6 text-end" style="justify-content: center; align-items: center; display: flex" v-if="newPCCList.length > 0 || resourcePCCList.length > 0">-->
<!--                                        <button type="button" class="btn btn-sm btn-outline-primary" style="height: 38px; margin-top: 10px" @click="showModalChange('new')">-->
<!--                                            <i class="fa fa-fw fa-plus"></i>-->
<!--                                            Add another PCC</button>-->
<!--                                        <button type="button" class="btn btn-sm btn-outline-primary" style="margin-left: 12px; height: 38px; margin-top: 10px" @click="showModalContactsChange('new')">-->
<!--                                            <i class="fa fa-fw fa-plus"></i>-->
<!--                                            Add Contacts</button>-->
<!--                                    </div>-->
<!--&lt;!&ndash;                                    <div class="col-6 text-center" v-else>&ndash;&gt;-->
<!--                                        <button type="button" class="btn btn-outline-primary" @click="showModalChange('new')">-->
<!--                                            <i class="fa fa-fw fa-plus"></i>-->
<!--                                            Add PCC</button>-->
<!--                                        <button type="button" class="btn btn-sm btn-outline-primary" style="margin-left: 12px; height: 38px; margin-top: 10px" @click="showModalContactsChange('new')">-->
<!--                                            <i class="fa fa-fw fa-plus"></i>-->
<!--                                            Add Contacts</button>-->
<!--                                    </div>-->


                                    <div class="col-12 pt-3" v-if="newContactsList.length > 0">
                                        <h4>New Contacts</h4>
                                        <div class="table-responsive">
                                            <table class="table table-striped table-vcenter acms-table">
                                                <thead>
                                                <tr>
                                                    <!--                                <th class="text-center" style="width: 50px;">Id</th>-->
                                                    <th>Person</th>
                                                    <th>Department/Positions</th>
                                                    <th>Phone Number</th>
                                                    <th>Email</th>
                                                    <th>Description</th>
                                                    <th class="text-end" style="width: 120px;">Actions</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="item in newContactsList">
                                                    <td>{{ item.person }}</td>
                                                    <td>{{ item.department }}</td>
                                                    <td>{{ item.phone }}</td>
                                                    <td>{{ item.email }}</td>
                                                    <td>{{ item.description }}</td>
                                                    <td class="text-end">
                                                        <div class="btn-group">
                                                            <button type="button"
                                                                    class="btn btn-sm btn-alt-secondary"
                                                                    @click="editNewContact(item.id_tmp)"
                                                                    data-bs-toggle="tooltip"
                                                                    data-bs-original-title="Edit Contact">
                                                                <i class="fa fa-fw fa-pen"></i>
                                                            </button>
                                                            <button type="button"
                                                                    class="btn btn-sm btn-alt-secondary d-flex align-items-center"
                                                                    @click="deleteContactsNewItem(item.id_tmp)"
                                                                    data-bs-toggle="tooltip"
                                                                    data-bs-original-title="Remove Contact"
                                                            >
                                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M13.07 11.97c.3.38.3.75 0 1.13-.38.31-.76.31-1.14 0L8 9.15 4.03 13.1c-.37.31-.75.31-1.13 0-.31-.38-.31-.75 0-1.13l3.97-3.95-3.97-4c-.31-.37-.31-.74 0-1.12.38-.31.76-.31 1.13 0L8 6.89l3.97-3.96c.37-.3.75-.3 1.13 0 .31.38.31.76 0 1.13L9.13 8.02l3.94 3.95Z" fill="CurrentColor"/>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="col-12 pt-3" v-if="resourceContactsList.length > 0">
                                        <h4>Contacts</h4>
                                        <div class="table-responsive">
                                            <table class="table table-striped table-vcenter acms-table">
                                                <thead>
                                                <tr>
                                                    <!--                                <th class="text-center" style="width: 50px;">Id</th>-->
                                                    <th>Person</th>
                                                    <th>Department/Positions</th>
                                                    <th>Phone Number</th>
                                                    <th>Email</th>
                                                    <th>Description</th>
                                                    <th class="text-end" style="width: 120px;">Actions</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="item in resourceContactsList ">
                                                    <td>{{ item.person }}</td>
                                                    <td>{{ item.department }}</td>
                                                    <td>{{ item.phone }}</td>
                                                    <td>{{ item.email }}</td>
                                                    <td>{{ item.description }}</td>
                                                    <td class="text-end">
                                                        <div class="btn-group">
                                                            <button type="button"
                                                                    class="btn btn-sm btn-alt-secondary"
                                                                    @click="editContact(item.id)"
                                                                    data-bs-toggle="tooltip"
                                                                    data-bs-original-title="Edit PCC">
                                                                <i class="fa fa-fw fa-pen"></i>
                                                            </button>
                                                            <button type="button"
                                                                    class="btn btn-sm btn-alt-secondary d-flex align-items-center"
                                                                    @click="deleteContactItem(item.id)"
                                                                    data-bs-toggle="tooltip"
                                                                    data-bs-original-title="Remove PCC"
                                                            >
                                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M13.07 11.97c.3.38.3.75 0 1.13-.38.31-.76.31-1.14 0L8 9.15 4.03 13.1c-.37.31-.75.31-1.13 0-.31-.38-.31-.75 0-1.13l3.97-3.95-3.97-4c-.31-.37-.31-.74 0-1.12.38-.31.76-.31 1.13 0L8 6.89l3.97-3.96c.37-.3.75-.3 1.13 0 .31.38.31.76 0 1.13L9.13 8.02l3.94 3.95Z" fill="CurrentColor"/>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="col-12 pt-3" v-if="newPCCList.length > 0">
                                        <h4>New PCCs</h4>
                                        <div class="table-responsive">
                                            <table class="table table-striped table-vcenter acms-table">
                                                <thead>
                                                <tr>
                                                    <!--                                <th class="text-center" style="width: 50px;">Id</th>-->
                                                    <th>PCC</th>
                                                    <th>Bridge</th>
                                                    <th>GDS</th>
                                                    <th>Location</th>
                                                    <th>Type</th>
                                                    <th>Currency</th>
                                                    <th>FARE TYPE</th>
                                                    <th>DK NUMBER</th>
                                                    <th>COMMISSION %</th>
                                                    <th class="text-end" style="width: 120px;">Actions</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="item in newPCCList">
                                                    <td>{{ item.pcc }}</td>
                                                    <td>{{ item.bridge }}</td>
                                                    <td>{{ findItemTitle(item.gds_id, props.gdsList) }}</td>
                                                    <td>{{ findItemTitle(item.country_id, props.countries) }}</td>
                                                    <td>{{ findItemTitle(item.type_id, props.pccTypes) }}</td>
                                                    <td>{{ findItemTitle(item.currency_id, props.currencies) }}</td>
                                                    <td>{{ item.default_fare_type }}</td>
                                                    <td>{{ item.dk_number }}</td>
                                                    <td>{{ item.commission_percent }}</td>
                                                    <td class="text-end">
                                                        <div class="btn-group">
                                                            <button type="button"
                                                                    class="btn btn-sm btn-alt-secondary"
                                                                    @click="editNewPCC(item.id_tmp)"
                                                                    data-bs-toggle="tooltip"
                                                                    data-bs-original-title="Edit PCC">
                                                                <i class="fa fa-fw fa-pen"></i>
                                                            </button>
                                                            <button type="button"
                                                                  class="btn btn-sm btn-alt-secondary d-flex align-items-center"
                                                                  @click="deletePCCNewItem(item.id_tmp)"
                                                                  data-bs-toggle="tooltip"
                                                                  data-bs-original-title="Remove PCC"
                                                            >
                                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M13.07 11.97c.3.38.3.75 0 1.13-.38.31-.76.31-1.14 0L8 9.15 4.03 13.1c-.37.31-.75.31-1.13 0-.31-.38-.31-.75 0-1.13l3.97-3.95-3.97-4c-.31-.37-.31-.74 0-1.12.38-.31.76-.31 1.13 0L8 6.89l3.97-3.96c.37-.3.75-.3 1.13 0 .31.38.31.76 0 1.13L9.13 8.02l3.94 3.95Z" fill="CurrentColor"/>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="col-12 pt-3" v-if="resourcePCCList.length > 0">
                                        <h4>Other PCCs</h4>
                                        <div class="table-responsive">
                                            <table class="table table-striped table-vcenter acms-table">
                                                <thead>
                                                <tr>
                                                    <!--                                <th class="text-center" style="width: 50px;">Id</th>-->
                                                    <th>PCC</th>
                                                    <th>Bridge</th>
                                                    <th>GDS</th>
                                                    <th>Location</th>
                                                    <th>Type</th>
                                                    <th>Currency</th>
                                                    <th>Fare Type</th>
                                                    <th>DK Number</th>
                                                    <th>COMM %</th>
                                                    <th class="text-end" style="width: 120px;">Actions</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="item in resourcePCCList ">
                                                    <td>{{ item.pcc }}</td>
                                                    <td>{{ item.bridge }}</td>
                                                    <td>{{ item.gds.title }}</td>
                                                    <td>{{ item.country.title }}</td>
                                                    <td>{{ item.type.title }}</td>
                                                    <td>{{ item.currency.title }}</td>
                                                    <td>{{ item.default_fare_type }}</td>
                                                    <td>{{ item.dk_number }}</td>
                                                    <td>{{ item.commission_percent }}</td>
                                                    <td class="text-end">
                                                        <div class="btn-group">
                                                            <button type="button"
                                                                    class="btn btn-sm btn-alt-secondary"
                                                                    @click="editPCC(item.id)"
                                                                    data-bs-toggle="tooltip"
                                                                    data-bs-original-title="Edit PCC">
                                                                <i class="fa fa-fw fa-pen"></i>
                                                            </button>
                                                            <button type="button"
                                                                  class="btn btn-sm btn-alt-secondary d-flex align-items-center"
                                                                  @click="deletePCCItem(item.id)"
                                                                  data-bs-toggle="tooltip"
                                                                  data-bs-original-title="Remove PCC"
                                                            >
                                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M13.07 11.97c.3.38.3.75 0 1.13-.38.31-.76.31-1.14 0L8 9.15 4.03 13.1c-.37.31-.75.31-1.13 0-.31-.38-.31-.75 0-1.13l3.97-3.95-3.97-4c-.31-.37-.31-.74 0-1.12.38-.31.76-.31 1.13 0L8 6.89l3.97-3.96c.37-.3.75-.3 1.13 0 .31.38.31.76 0 1.13L9.13 8.02l3.94 3.95Z" fill="CurrentColor"/>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div>
                                        <table class="table table-bordered acms-table table-consolidator">
                                            <thead>
                                            <tr>
                                                <th rowspan="2" style="text-align: center; vertical-align: middle">Fare Type</th>
                                                <th colspan="2">Business</th>
                                                <th colspan="2">First</th>
                                                <th colspan="2">Premium</th>
                                                <th colspan="2">Economy</th>
                                            </tr>
                                            <tr>
                                                <th>OW</th>
                                                <th>RT</th>
                                                <th>OW</th>
                                                <th>RT</th>
                                                <th>OW</th>
                                                <th>RT</th>
                                                <th>OW</th>
                                                <th>RT</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td colspan="9" style="background-color: rgb(209, 230, 203); text-align: center">NET</td>
                                            </tr>
                                            <tr>
                                                <td>Issuing</td>
                                                <td>
                                                    <input-simple
                                                        name="net_issuing_business_ow"
                                                        type="number"
                                                        v-model="form.net_issuing_business_ow"
                                                        :hint="form.errors.net_issuing_business_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="net_issuing_business_rt"
                                                        type="number"
                                                        v-model="form.net_issuing_business_rt"
                                                        :hint="form.errors.net_issuing_business_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="net_issuing_first_ow"
                                                        type="number"
                                                        v-model="form.net_issuing_first_ow"
                                                        :hint="form.errors.net_issuing_first_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="net_issuing_first_rt"
                                                        type="number"
                                                        v-model="form.net_issuing_first_rt"
                                                        :hint="form.errors.net_issuing_first_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="net_issuing_premium_ow"
                                                        type="number"
                                                        v-model="form.net_issuing_premium_ow"
                                                        :hint="form.errors.net_issuing_premium_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="net_issuing_premium_rt"
                                                        type="number"
                                                        v-model="form.net_issuing_premium_rt"
                                                        :hint="form.errors.net_issuing_premium_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="net_issuing_economy_ow"
                                                        type="number"
                                                        v-model="form.net_issuing_economy_ow"
                                                        :hint="form.errors.net_issuing_economy_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="net_issuing_economy_rt"
                                                        type="number"
                                                        v-model="form.net_issuing_economy_rt"
                                                        :hint="form.errors.net_issuing_economy_rt"
                                                    ></input-simple>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Void</td>
                                                <td>
                                                    <input-simple
                                                        name="net_void_business_ow"
                                                        type="number"
                                                        v-model="form.net_void_business_ow"
                                                        :hint="form.errors.net_void_business_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="net_void_business_rt"
                                                        type="number"
                                                        v-model="form.net_void_business_rt"
                                                        :hint="form.errors.net_void_business_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="net_void_first_ow"
                                                        type="number"
                                                        v-model="form.net_void_first_ow"
                                                        :hint="form.errors.net_void_first_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="net_void_first_rt"
                                                        type="number"
                                                        v-model="form.net_void_first_rt"
                                                        :hint="form.errors.net_void_first_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="net_void_premium_ow"
                                                        type="number"
                                                        v-model="form.net_void_premium_ow"
                                                        :hint="form.errors.net_void_premium_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="net_void_premium_rt"
                                                        type="number"
                                                        v-model="form.net_void_premium_rt"
                                                        :hint="form.errors.net_void_premium_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="net_void_economy_ow"
                                                        type="number"
                                                        v-model="form.net_void_economy_ow"
                                                        :hint="form.errors.net_void_economy_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="net_void_economy_rt"
                                                        type="number"
                                                        v-model="form.net_void_economy_rt"
                                                        :hint="form.errors.net_void_economy_rt"
                                                    ></input-simple>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Exchange</td>
                                                <td>
                                                    <input-simple
                                                        name="net_exchange_business_ow"
                                                        type="number"
                                                        v-model="form.net_exchange_business_ow"
                                                        :hint="form.errors.net_exchange_business_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="net_exchange_business_rt"
                                                        type="number"
                                                        v-model="form.net_exchange_business_rt"
                                                        :hint="formPCC.errors.business_net_pub_issuing"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="net_exchange_first_ow"
                                                        type="number"
                                                        v-model="form.net_exchange_first_ow"
                                                        :hint="form.errors.net_exchange_first_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="net_exchange_first_rt"
                                                        type="number"
                                                        v-model="form.net_exchange_first_rt"
                                                        :hint="form.errors.net_exchange_first_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="net_exchange_premium_ow"
                                                        type="number"
                                                        v-model="form.net_exchange_premium_ow"
                                                        :hint="form.errors.net_exchange_premium_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="net_exchange_premium_rt"
                                                        type="number"
                                                        v-model="form.net_exchange_premium_rt"
                                                        :hint="form.errors.net_exchange_premium_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="net_exchange_economy_ow"
                                                        type="number"
                                                        v-model="form.net_exchange_economy_ow"
                                                        :hint="form.errors.net_exchange_economy_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="net_exchange_economy_rt"
                                                        type="number"
                                                        v-model="form.net_exchange_economy_rt"
                                                        :hint="form.errors.net_exchange_economy_rt"
                                                    ></input-simple>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Refund</td>
                                                <td>
                                                    <input-simple
                                                        name="net_refund_business_ow"
                                                        type="number"
                                                        v-model="form.net_refund_business_ow"
                                                        :hint="form.errors.net_refund_business_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="net_refund_business_rt"
                                                        type="number"
                                                        v-model="form.net_refund_business_rt"
                                                        :hint="form.errors.net_refund_business_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="net_refund_first_ow"
                                                        type="number"
                                                        v-model="form.net_refund_first_ow"
                                                        :hint="form.errors.net_refund_first_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="net_refund_first_rt"
                                                        type="number"
                                                        v-model="form.net_refund_first_rt"
                                                        :hint="form.errors.net_refund_first_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="net_refund_premium_ow"
                                                        type="number"
                                                        v-model="form.net_refund_premium_ow"
                                                        :hint="form.errors.net_refund_premium_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="net_refund_premium_rt"
                                                        type="number"
                                                        v-model="form.net_refund_premium_rt"
                                                        :hint="form.errors.net_refund_premium_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="net_refund_economy_ow"
                                                        type="number"
                                                        v-model="form.net_refund_economy_ow"
                                                        :hint="form.errors.net_refund_economy_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="net_refund_economy_rt"
                                                        type="number"
                                                        v-model="form.net_refund_economy_rt"
                                                        :hint="form.errors.net_refund_economy_rt"
                                                    ></input-simple>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="9" style="background-color: rgb(209, 230, 203); text-align: center">PUB</td>
                                            </tr>
                                            <tr>
                                                <td>Issuing</td>
                                                <td>
                                                    <input-simple
                                                        name="pub_issuing_business_ow"
                                                        type="number"
                                                        v-model="form.pub_issuing_business_ow"
                                                        :hint="form.errors.pub_issuing_business_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="pub_issuing_business_rt"
                                                        type="number"
                                                        v-model="form.pub_issuing_business_rt"
                                                        :hint="form.errors.pub_issuing_business_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="pub_issuing_first_ow"
                                                        type="number"
                                                        v-model="form.pub_issuing_first_ow"
                                                        :hint="form.errors.pub_issuing_first_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="pub_issuing_first_rt"
                                                        type="number"
                                                        v-model="form.pub_issuing_first_rt"
                                                        :hint="form.errors.pub_issuing_first_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="pub_issuing_premium_ow"
                                                        type="number"
                                                        v-model="form.pub_issuing_premium_ow"
                                                        :hint="form.errors.pub_issuing_premium_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="pub_issuing_premium_rt"
                                                        type="number"
                                                        v-model="form.pub_issuing_premium_rt"
                                                        :hint="form.errors.pub_issuing_premium_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="pub_issuing_economy_ow"
                                                        type="number"
                                                        v-model="form.pub_issuing_economy_ow"
                                                        :hint="form.errors.pub_issuing_economy_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="pub_issuing_economy_rt"
                                                        type="number"
                                                        v-model="form.pub_issuing_economy_rt"
                                                        :hint="form.errors.pub_issuing_economy_rt"
                                                    ></input-simple>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Void</td>
                                                <td>
                                                    <input-simple
                                                        name="pub_void_business_ow"
                                                        type="number"
                                                        v-model="form.pub_void_business_ow"
                                                        :hint="form.errors.pub_void_business_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="pub_void_business_rt"
                                                        type="number"
                                                        v-model="form.pub_void_business_rt"
                                                        :hint="form.errors.pub_void_business_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="pub_void_first_ow"
                                                        type="number"
                                                        v-model="form.pub_void_first_ow"
                                                        :hint="form.errors.pub_void_first_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="pub_void_first_rt"
                                                        type="number"
                                                        v-model="form.pub_void_first_rt"
                                                        :hint="form.errors.pub_void_first_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="pub_void_premium_ow"
                                                        type="number"
                                                        v-model="form.pub_void_premium_ow"
                                                        :hint="form.errors.pub_void_premium_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="pub_void_premium_rt"
                                                        type="number"
                                                        v-model="form.pub_void_premium_rt"
                                                        :hint="form.errors.pub_void_premium_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="pub_void_economy_ow"
                                                        type="number"
                                                        v-model="form.pub_void_economy_ow"
                                                        :hint="form.errors.pub_void_economy_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="pub_void_economy_rt"
                                                        type="number"
                                                        v-model="form.pub_void_economy_rt"
                                                        :hint="form.errors.pub_void_economy_rt"
                                                    ></input-simple>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Exchange</td>
                                                <td>
                                                    <input-simple
                                                        name="pub_exchange_business_ow"
                                                        type="number"
                                                        v-model="form.pub_exchange_business_ow"
                                                        :hint="form.errors.pub_exchange_business_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="pub_exchange_business_rt"
                                                        type="number"
                                                        v-model="form.pub_exchange_business_rt"
                                                        :hint="form.errors.pub_exchange_business_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="pub_exchange_first_ow"
                                                        type="number"
                                                        v-model="form.pub_exchange_first_ow"
                                                        :hint="form.errors.pub_exchange_first_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="pub_exchange_first_rt"
                                                        type="number"
                                                        v-model="form.pub_exchange_first_rt"
                                                        :hint="form.errors.pub_exchange_first_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="pub_exchange_premium_ow"
                                                        type="number"
                                                        v-model="form.pub_exchange_premium_ow"
                                                        :hint="form.errors.pub_exchange_premium_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="pub_exchange_premium_rt"
                                                        type="number"
                                                        v-model="form.pub_exchange_premium_rt"
                                                        :hint="form.errors.pub_exchange_premium_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="pub_exchange_economy_ow"
                                                        type="number"
                                                        v-model="form.pub_exchange_economy_ow"
                                                        :hint="form.errors.pub_exchange_economy_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="pub_exchange_economy_rt"
                                                        type="number"
                                                        v-model="form.pub_exchange_economy_rt"
                                                        :hint="form.errors.pub_exchange_economy_rt"
                                                    ></input-simple>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Refund</td>
                                                <td>
                                                    <input-simple
                                                        name="pub_refund_business_ow"
                                                        type="number"
                                                        v-model="form.pub_refund_business_ow"
                                                        :hint="form.errors.pub_refund_business_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="pub_refund_business_rt"
                                                        type="number"
                                                        v-model="form.pub_refund_business_rt"
                                                        :hint="form.errors.pub_refund_business_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="pub_refund_first_ow"
                                                        type="number"
                                                        v-model="form.pub_refund_first_ow"
                                                        :hint="form.errors.pub_refund_first_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="pub_refund_first_rt"
                                                        type="number"
                                                        v-model="form.pub_refund_first_rt"
                                                        :hint="form.errors.pub_refund_first_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="pub_refund_premium_ow"
                                                        type="number"
                                                        v-model="form.pub_refund_premium_ow"
                                                        :hint="form.errors.pub_refund_premium_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="pub_refund_premium_rt"
                                                        type="number"
                                                        v-model="form.pub_refund_premium_rt"
                                                        :hint="form.errors.pub_refund_premium_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="pub_refund_economy_ow"
                                                        type="number"
                                                        v-model="form.pub_refund_economy_ow"
                                                        :hint="form.errors.pub_refund_economy_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="pub_refund_economy_rt"
                                                        type="number"
                                                        v-model="form.pub_refund_economy_rt"
                                                        :hint="form.errors.pub_refund_economy_rt"
                                                    ></input-simple>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="9" style="background-color: rgb(209, 230, 203); text-align: center">TOUR</td>
                                            </tr>
                                            <tr>
                                                <td>Issuing</td>
                                                <td>
                                                    <input-simple
                                                        name="tour_issuing_business_ow"
                                                        type="number"
                                                        v-model="form.tour_issuing_business_ow"
                                                        :hint="form.errors.tour_issuing_business_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="tour_issuing_business_rt"
                                                        type="number"
                                                        v-model="form.tour_issuing_business_rt"
                                                        :hint="form.errors.tour_issuing_business_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="tour_issuing_first_ow"
                                                        type="number"
                                                        v-model="form.tour_issuing_first_ow"
                                                        :hint="form.errors.tour_issuing_first_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="tour_issuing_first_rt"
                                                        type="number"
                                                        v-model="form.tour_issuing_first_rt"
                                                        :hint="form.errors.tour_issuing_first_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="tour_issuing_premium_ow"
                                                        type="number"
                                                        v-model="form.tour_issuing_premium_ow"
                                                        :hint="form.errors.tour_issuing_premium_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="tour_issuing_premium_rt"
                                                        type="number"
                                                        v-model="form.tour_issuing_premium_rt"
                                                        :hint="form.errors.tour_issuing_premium_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="tour_issuing_economy_ow"
                                                        type="number"
                                                        v-model="form.tour_issuing_economy_ow"
                                                        :hint="form.errors.tour_issuing_economy_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="tour_issuing_economy_rt"
                                                        type="number"
                                                        v-model="form.tour_issuing_economy_rt"
                                                        :hint="form.errors.tour_issuing_economy_rt"
                                                    ></input-simple>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Void</td>
                                                <td>
                                                    <input-simple
                                                        name="tour_void_business_ow"
                                                        type="number"
                                                        v-model="form.tour_void_business_ow"
                                                        :hint="form.errors.tour_void_business_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="tour_void_business_rt"
                                                        type="number"
                                                        v-model="form.tour_void_business_rt"
                                                        :hint="form.errors.tour_void_business_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="tour_void_first_ow"
                                                        type="number"
                                                        v-model="form.tour_void_first_ow"
                                                        :hint="form.errors.tour_void_first_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="tour_void_first_rt"
                                                        type="number"
                                                        v-model="form.tour_void_first_rt"
                                                        :hint="form.errors.tour_void_first_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="tour_void_premium_ow"
                                                        type="number"
                                                        v-model="form.tour_void_premium_ow"
                                                        :hint="form.errors.tour_void_premium_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="tour_void_premium_rt"
                                                        type="number"
                                                        v-model="form.tour_void_premium_rt"
                                                        :hint="form.errors.tour_void_premium_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="tour_void_economy_ow"
                                                        type="number"
                                                        v-model="form.tour_void_economy_ow"
                                                        :hint="form.errors.tour_void_economy_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="tour_void_economy_rt"
                                                        type="number"
                                                        v-model="form.tour_void_economy_rt"
                                                        :hint="form.errors.tour_void_economy_rt"
                                                    ></input-simple>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Exchange</td>
                                                <td>
                                                    <input-simple
                                                        name="tour_exchange_business_ow"
                                                        type="number"
                                                        v-model="form.tour_exchange_business_ow"
                                                        :hint="form.errors.tour_exchange_business_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="tour_exchange_business_rt"
                                                        type="number"
                                                        v-model="form.tour_exchange_business_rt"
                                                        :hint="form.errors.tour_exchange_business_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="tour_exchange_first_ow"
                                                        type="number"
                                                        v-model="form.tour_exchange_first_ow"
                                                        :hint="form.errors.tour_exchange_first_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="tour_exchange_first_rt"
                                                        type="number"
                                                        v-model="form.tour_exchange_first_rt"
                                                        :hint="form.errors.tour_exchange_first_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="tour_exchange_premium_ow"
                                                        type="number"
                                                        v-model="form.tour_exchange_premium_ow"
                                                        :hint="form.errors.tour_exchange_premium_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="tour_exchange_premium_rt"
                                                        type="number"
                                                        v-model="form.tour_exchange_premium_rt"
                                                        :hint="form.errors.tour_exchange_premium_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="tour_exchange_economy_ow"
                                                        type="number"
                                                        v-model="form.tour_exchange_economy_ow"
                                                        :hint="form.errors.tour_exchange_economy_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="tour_exchange_economy_rt"
                                                        type="number"
                                                        v-model="form.tour_exchange_economy_rt"
                                                        :hint="form.errors.tour_exchange_economy_rt"
                                                    ></input-simple>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Refund</td>
                                                <td>
                                                    <input-simple
                                                        name="tour_refund_business_ow"
                                                        type="number"
                                                        v-model="form.tour_refund_business_ow"
                                                        :hint="form.errors.tour_refund_business_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="tour_refund_business_rt"
                                                        type="number"
                                                        v-model="form.tour_refund_business_rt"
                                                        :hint="form.errors.tour_refund_business_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="tour_refund_first_ow"
                                                        type="number"
                                                        v-model="form.tour_refund_first_ow"
                                                        :hint="form.errors.tour_refund_first_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="tour_refund_first_rt"
                                                        type="number"
                                                        v-model="form.tour_refund_first_rt"
                                                        :hint="form.errors.tour_refund_first_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="tour_refund_premium_ow"
                                                        type="number"
                                                        v-model="form.tour_refund_premium_ow"
                                                        :hint="form.errors.tour_refund_premium_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="tour_refund_premium_rt"
                                                        type="number"
                                                        v-model="form.tour_refund_premium_rt"
                                                        :hint="form.errors.tour_refund_premium_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="tour_refund_economy_ow"
                                                        type="number"
                                                        v-model="form.tour_refund_economy_ow"
                                                        :hint="form.errors.tour_refund_economy_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="tour_refund_economy_rt"
                                                        type="number"
                                                        v-model="form.tour_refund_economy_rt"
                                                        :hint="form.errors.tour_refund_economy_rt"
                                                    ></input-simple>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="9" style="background-color: rgb(209, 230, 203); text-align: center">SEA</td>
                                            </tr>
                                            <tr>
                                                <td>Issuing</td>
                                                <td>
                                                    <input-simple
                                                        name="sea_issuing_business_ow"
                                                        type="number"
                                                        v-model="form.sea_issuing_business_ow"
                                                        :hint="form.errors.sea_issuing_business_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="sea_issuing_business_rt"
                                                        type="number"
                                                        v-model="form.sea_issuing_business_rt"
                                                        :hint="form.errors.sea_issuing_business_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="sea_issuing_first_ow"
                                                        type="number"
                                                        v-model="form.sea_issuing_first_ow"
                                                        :hint="form.errors.sea_issuing_first_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="sea_issuing_first_rt"
                                                        type="number"
                                                        v-model="form.sea_issuing_first_rt"
                                                        :hint="form.errors.sea_issuing_first_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="sea_issuing_premium_ow"
                                                        type="number"
                                                        v-model="form.sea_issuing_premium_ow"
                                                        :hint="form.errors.sea_issuing_premium_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="sea_issuing_premium_rt"
                                                        type="number"
                                                        v-model="form.sea_issuing_premium_rt"
                                                        :hint="form.errors.sea_issuing_premium_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="sea_issuing_economy_ow"
                                                        type="number"
                                                        v-model="form.sea_issuing_economy_ow"
                                                        :hint="form.errors.sea_issuing_economy_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="sea_issuing_economy_rt"
                                                        type="number"
                                                        v-model="form.sea_issuing_economy_rt"
                                                        :hint="form.errors.sea_issuing_economy_rt"
                                                    ></input-simple>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Void</td>
                                                <td>
                                                    <input-simple
                                                        name="sea_void_business_ow"
                                                        type="number"
                                                        v-model="form.sea_void_business_ow"
                                                        :hint="form.errors.sea_void_business_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="sea_void_business_rt"
                                                        type="number"
                                                        v-model="form.sea_void_business_rt"
                                                        :hint="form.errors.sea_void_business_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="sea_void_first_ow"
                                                        type="number"
                                                        v-model="form.sea_void_first_ow"
                                                        :hint="form.errors.sea_void_first_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="sea_void_first_rt"
                                                        type="number"
                                                        v-model="form.sea_void_first_rt"
                                                        :hint="form.errors.sea_void_first_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="sea_void_premium_ow"
                                                        type="number"
                                                        v-model="form.sea_void_premium_ow"
                                                        :hint="form.errors.sea_void_premium_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="sea_void_premium_rt"
                                                        type="number"
                                                        v-model="form.sea_void_premium_rt"
                                                        :hint="form.errors.sea_void_premium_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="sea_void_economy_ow"
                                                        type="number"
                                                        v-model="form.sea_void_economy_ow"
                                                        :hint="form.errors.sea_void_economy_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="sea_void_economy_rt"
                                                        type="number"
                                                        v-model="form.sea_void_economy_rt"
                                                        :hint="form.errors.sea_void_economy_rt"
                                                    ></input-simple>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Exchange</td>
                                                <td>
                                                    <input-simple
                                                        name="sea_exchange_business_ow"
                                                        type="number"
                                                        v-model="form.sea_exchange_business_ow"
                                                        :hint="form.errors.sea_exchange_business_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="sea_exchange_business_rt"
                                                        type="number"
                                                        v-model="form.sea_exchange_business_rt"
                                                        :hint="form.errors.sea_exchange_business_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="sea_exchange_first_ow"
                                                        type="number"
                                                        v-model="form.sea_exchange_first_ow"
                                                        :hint="form.errors.sea_exchange_first_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="sea_exchange_first_rt"
                                                        type="number"
                                                        v-model="form.sea_exchange_first_rt"
                                                        :hint="form.errors.sea_exchange_first_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="sea_exchange_premium_ow"
                                                        type="number"
                                                        v-model="form.sea_exchange_premium_ow"
                                                        :hint="form.errors.sea_exchange_premium_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="sea_exchange_premium_rt"
                                                        type="number"
                                                        v-model="form.sea_exchange_premium_rt"
                                                        :hint="form.errors.sea_exchange_premium_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="sea_exchange_economy_ow"
                                                        type="number"
                                                        v-model="form.sea_exchange_economy_ow"
                                                        :hint="form.errors.sea_exchange_economy_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="sea_exchange_economy_rt"
                                                        type="number"
                                                        v-model="form.sea_exchange_economy_rt"
                                                        :hint="form.errors.sea_exchange_economy_rt"
                                                    ></input-simple>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Refund</td>
                                                <td>
                                                    <input-simple
                                                        name="sea_refund_business_ow"
                                                        type="number"
                                                        v-model="form.sea_refund_business_ow"
                                                        :hint="form.errors.sea_refund_business_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="sea_refund_business_rt"
                                                        type="number"
                                                        v-model="form.sea_refund_business_rt"
                                                        :hint="form.errors.sea_refund_business_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="sea_refund_first_ow"
                                                        type="number"
                                                        v-model="form.sea_refund_first_ow"
                                                        :hint="form.errors.sea_refund_first_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="sea_refund_first_rt"
                                                        type="number"
                                                        v-model="form.sea_refund_first_rt"
                                                        :hint="form.errors.sea_refund_first_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="sea_refund_premium_ow"
                                                        type="number"
                                                        v-model="form.sea_refund_premium_ow"
                                                        :hint="form.errors.sea_refund_premium_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="sea_refund_premium_rt"
                                                        type="number"
                                                        v-model="form.sea_refund_premium_rt"
                                                        :hint="form.errors.sea_refund_premium_rt"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="sea_refund_economy_ow"
                                                        type="number"
                                                        v-model="form.sea_refund_economy_ow"
                                                        :hint="form.errors.sea_refund_economy_ow"
                                                    ></input-simple>
                                                </td>
                                                <td>
                                                    <input-simple
                                                        name="sea_refund_economy_rt"
                                                        type="number"
                                                        v-model="form.sea_refund_economy_rt"
                                                        :hint="form.errors.sea_refund_economy_rt"
                                                    ></input-simple>
                                                </td>
                                            </tr>

                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </form>
                        <!-- Form End -->

                        <modal header-name="Add PCC" :visibleModal="showModal" @hide-modal="showModalChange">
                            <template v-slot:modal-body>
                                <!-- Form Start -->
                                <form>
                                    <div class="row g-12">
                                        <div class="col-12 col-sm-4 col-xl-2">
                                            <input-simple
                                                label="PCC"
                                                name="pcc"
                                                required
                                                placeholder="Enter PCC"
                                                v-model="formPCC.pcc"
                                                :hint="formPCC.errors.pcc"
                                                uppercase
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-4 col-xl-2">
                                            <input-simple
                                                label="Bridge"
                                                name="bridge"
                                                required
                                                placeholder="Enter bridge"
                                                v-model="formPCC.bridge"
                                                :hint="formPCC.errors.bridge"
                                                uppercase
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-4 col-xl-2">
                                            <select-custom label="GDS"
                                                           required
                                                           @select-option="(e)=>handleSelect(e,'gds_id')"
                                                           :value="formPCC.gds_id"
                                                           :options="gdsList"
                                                           :hint="formPCC.errors.gds_id"
                                                           @search="searchCountry"
                                                           name="gds_id"
                                                           placeholder="Select GDS"
                                            ></select-custom>
                                        </div>
                                        <div class="col-12 col-sm-4 col-xl-2">
                                            <select-custom label="Location"
                                                           required
                                                           enableSearch
                                                           largeWidth
                                                           @select-option="(e)=>handleSelect(e,'country_id')"
                                                           :value="formPCC.country_id"
                                                           :options="countriesList"
                                                           :hint="formPCC.errors.country_id"
                                                           @search="searchCountry"
                                                           name="country_id"
                                                           placeholder="Select Location"
                                            ></select-custom>

                                        </div>
                                        <div class="col-12 col-sm-4 col-xl-2">
                                            <select-custom label="Type"
                                                           required
                                                           @select-option="(e)=>handleSelect(e,'type_id')"
                                                           :value="formPCC.type_id"
                                                           :options="pccTypesList"
                                                           :hint="formPCC.errors.type_id"
                                                           @search="searchCountry"
                                                           name="type_id"
                                                           placeholder="Select Type"
                                            ></select-custom>
                                        </div>
                                        <div class="col-12 col-sm-4 col-xl-2">
                                            <select-custom label="Currency"
                                                           required
                                                           @select-option="(e)=>handleSelect(e,'currency_id')"
                                                           :value="formPCC.currency_id"
                                                           :options="currencyList"
                                                           :hint="formPCC.errors.currency_id"
                                                           @search="searchCurrency"
                                                           name="currency_id"
                                                           placeholder="Select Currency"
                                            ></select-custom>
                                        </div>
                                        <div class="col-12 col-sm-4 col-xl-2">
                                            <select-custom label="Default Fare Type"
                                                           required
                                                           enableSearch
                                                           @select-option="(e)=>handleSelect(e,'default_fare_type')"
                                                           :value="formPCC.default_fare_type"
                                                           :options="['PVT', 'PUB', 'TUR', 'CRP', 'PUB/C']"
                                                           :hint="formPCC.errors.default_fare_type"
                                                           name="default_fare_type"
                                                           placeholder="Select Fare Type"
                                            ></select-custom>
                                        </div>
                                        <div v-if="formPCC.default_fare_type === 'PUB/C'" class="col-12 col-sm-4 col-xl-2">
                                            <input-simple
                                                label="Commission percent %"
                                                name="commission_percent"
                                                placeholder="%"
                                                v-model="formPCC.commission_percent"
                                                :hint="formPCC.errors.commission_percent"
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-4 col-xl-2">
                                            <input-simple
                                                label="DK Number"
                                                name="dk_number"
                                                placeholder="Enter DK"
                                                v-model="formPCC.dk_number"
                                                :hint="formPCC.errors.dk_number"
                                            ></input-simple>
                                        </div>
<!--                                        <div class="col-12">-->

<!--                                            <div class="table-responsive">-->
<!--&lt;!&ndash;                                                <table class="table table-bordered acms-table table-consolidator">&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <thead>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <tr>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <th rowspan="2"></th>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <th colspan="2">Net & PUB</th>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <th colspan="2">Tours</th>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <th>Void, {{ findCurrencySymbol(formPCC.currency_id, props.currencies) }}</th>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </tr>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <tr>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <th>Issuing, {{ findCurrencySymbol(formPCC.currency_id, props.currencies) }}</th>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <th>Ref./Exch, {{ findCurrencySymbol(formPCC.currency_id, props.currencies) }}</th>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <th>Issuing, {{ findCurrencySymbol(formPCC.currency_id, props.currencies) }}</th>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <th>Ref./Exch, {{ findCurrencySymbol(formPCC.currency_id, props.currencies) }}</th>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <th></th>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </tr>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </thead>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <tbody>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <tr>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <td>Business</td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <input-simple&ndash;&gt;-->
<!--&lt;!&ndash;                                                                name="business_net_pub_issuing"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                required&ndash;&gt;-->
<!--&lt;!&ndash;                                                                v-model="formPCC.business_net_pub_issuing"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                :hint="formPCC.errors.business_net_pub_issuing"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            ></input-simple>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <input-simple&ndash;&gt;-->
<!--&lt;!&ndash;                                                                name="business_net_pub_ref_exch"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                required&ndash;&gt;-->
<!--&lt;!&ndash;                                                                v-model="formPCC.business_net_pub_ref_exch"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                :hint="formPCC.errors.business_net_pub_ref_exch"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            ></input-simple>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <input-simple&ndash;&gt;-->
<!--&lt;!&ndash;                                                                name="business_tours_issuing"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                required&ndash;&gt;-->
<!--&lt;!&ndash;                                                                v-model="formPCC.business_tours_issuing"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                :hint="formPCC.errors.business_tours_issuing"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            ></input-simple>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <input-simple&ndash;&gt;-->
<!--&lt;!&ndash;                                                                name="business_tours_ref_exch"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                required&ndash;&gt;-->
<!--&lt;!&ndash;                                                                v-model="formPCC.business_tours_ref_exch"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                :hint="formPCC.errors.business_tours_ref_exch"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            ></input-simple>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <input-simple&ndash;&gt;-->
<!--&lt;!&ndash;                                                                name="business_void"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                required&ndash;&gt;-->
<!--&lt;!&ndash;                                                                v-model="formPCC.business_void"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                :hint="formPCC.errors.business_void"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            ></input-simple>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </tr>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <tr>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <td>Economy</td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <input-simple&ndash;&gt;-->
<!--&lt;!&ndash;                                                                name="economy_net_pub_issuing"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                required&ndash;&gt;-->
<!--&lt;!&ndash;                                                                v-model="formPCC.economy_net_pub_issuing"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                :hint="formPCC.errors.economy_net_pub_issuing"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            ></input-simple>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <input-simple&ndash;&gt;-->
<!--&lt;!&ndash;                                                                name="economy_net_pub_ref_exch"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                required&ndash;&gt;-->
<!--&lt;!&ndash;                                                                v-model="formPCC.economy_net_pub_ref_exch"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                :hint="formPCC.errors.economy_net_pub_ref_exch"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            ></input-simple>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <input-simple&ndash;&gt;-->
<!--&lt;!&ndash;                                                                name="economy_tours_issuing"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                required&ndash;&gt;-->
<!--&lt;!&ndash;                                                                v-model="formPCC.economy_tours_issuing"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                :hint="formPCC.errors.economy_tours_issuing"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            ></input-simple>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <input-simple&ndash;&gt;-->
<!--&lt;!&ndash;                                                                name="economy_tours_ref_exch"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                required&ndash;&gt;-->
<!--&lt;!&ndash;                                                                v-model="formPCC.economy_tours_ref_exch"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                :hint="formPCC.errors.economy_tours_ref_exch"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            ></input-simple>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <input-simple&ndash;&gt;-->
<!--&lt;!&ndash;                                                                name="economy_void"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                required&ndash;&gt;-->
<!--&lt;!&ndash;                                                                v-model="formPCC.economy_void"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                :hint="formPCC.errors.economy_void"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            ></input-simple>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </tr>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <tr>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <td>Premium</td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <input-simple&ndash;&gt;-->
<!--&lt;!&ndash;                                                                name="premium_net_pub_issuing"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                required&ndash;&gt;-->
<!--&lt;!&ndash;                                                                v-model="formPCC.premium_net_pub_issuing"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                :hint="formPCC.errors.premium_net_pub_issuing"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            ></input-simple>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <input-simple&ndash;&gt;-->
<!--&lt;!&ndash;                                                                name="premium_net_pub_ref_exch"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                required&ndash;&gt;-->
<!--&lt;!&ndash;                                                                v-model="formPCC.premium_net_pub_ref_exch"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                :hint="formPCC.errors.premium_net_pub_ref_exch"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            ></input-simple>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <input-simple&ndash;&gt;-->
<!--&lt;!&ndash;                                                                name="premium_tours_issuing"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                required&ndash;&gt;-->
<!--&lt;!&ndash;                                                                v-model="formPCC.premium_tours_issuing"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                :hint="formPCC.errors.premium_tours_issuing"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            ></input-simple>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <input-simple&ndash;&gt;-->
<!--&lt;!&ndash;                                                                name="premium_tours_ref_exch"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                required&ndash;&gt;-->
<!--&lt;!&ndash;                                                                v-model="formPCC.premium_tours_ref_exch"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                :hint="formPCC.errors.premium_tours_ref_exch"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            ></input-simple>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <input-simple&ndash;&gt;-->
<!--&lt;!&ndash;                                                                name="premium_void"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                required&ndash;&gt;-->
<!--&lt;!&ndash;                                                                v-model="formPCC.premium_void"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                :hint="formPCC.errors.premium_void"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            ></input-simple>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </td>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </tr>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </tbody>&ndash;&gt;-->
<!--&lt;!&ndash;                                                </table>&ndash;&gt;-->

<!--                                            </div>-->

<!--                                        </div>-->
                                        <div class="col-12">
                                            <textarea-simple
                                                label="Special notes"
                                                name="notes"
                                                placeholder="Enter special notes"
                                                v-model="formPCC.notes"
                                                :hint="formPCC.errors.notes"
                                            ></textarea-simple>
                                        </div>
                                    </div>
                                </form>
                                <!-- Form End -->
                            </template>

                            <template v-slot:modal-footer>
                                <button type="button" class="btn btn-primary" @click="submitFormPCC">Save</button>
                            </template>
                        </modal>
                        <modal header-name="Add Contacts" :visibleModal="showModalContacts" @hide-modal="showModalContactsChange">
                            <template v-slot:modal-body>
                                <!-- Form Start -->
                                <form>
                                    <div class="row g-12">
                                        <div class="col-12 col-sm-6 col-xl-3">
                                            <input-simple
                                                label="Contacts person"
                                                name="person"
                                                required
                                                placeholder="Enter name"
                                                v-model="formContacts.person"
                                                :hint="formContacts.errors.person"
                                            ></input-simple>
                                        </div>
                                        <div class="col-12 col-sm-6 col-xl-3">
                                            <input-simple
                                                label="Department/position"
                                                name="department"
                                                required
                                                placeholder="Enter department/position"
                                                v-model="formContacts.department"
                                                :hint="formContacts.errors.department"
                                            ></input-simple>
                                        </div>
<!--                                        <div class="col-12 col-sm-6 col-xl-3">-->
<!--                                            <input-simple-->
<!--                                                label="Phone"-->
<!--                                                name="Phone"-->
<!--                                                required-->
<!--                                                placeholder="Enter phone"-->
<!--                                                v-model="formContacts.phone"-->
<!--                                                :hint="formContacts.errors.phone"-->
<!--                                            ></input-simple>-->
<!--                                        </div>-->
                                        <div class="col-12 col-sm-6 col-xl-3">
                                            <label class="form-label">Phone number</label>
                                            <div class="code__phone_block"
                                                 :class="{'has-error':formContacts.errors.dial_code_id || formContacts.errors.phone}"
                                            >
                                                <div class="d-flex align-items-center">
                                                    <select-phone-code
                                                        enableSearch
                                                        @select-option="(e)=>handleSelectFormContacts(e,'dial_code_id')"
                                                        :value="formContacts.dial_code_id"
                                                        :options="dialCodes"
                                                        @search="searchDial"
                                                        name="dial_code_id"
                                                        placeholder="&nbsp;"
                                                    >
                                                    </select-phone-code>
                                                    <input-simple
                                                        name="phone"
                                                        placeholder="XXXXXXXXXX"
                                                        v-model="formContacts.phone"
                                                    ></input-simple>
                                                </div>
                                                <div class="form-hint" v-if="formContacts.errors.dial_code_id">
                                                    {{ formContacts.errors.dial_code_id }}
                                                </div>
                                                <div class="form-hint" v-if="formContacts.errors.phone">
                                                    {{ formContacts.errors.phone }}
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-12 col-sm-6 col-xl-3">
                                            <input-simple
                                                label="Email"
                                                name="email"
                                                required
                                                type="email"
                                                placeholder="Enter email"
                                                v-model="formContacts.email"
                                                :hint="formContacts.errors.email"
                                            ></input-simple>
                                        </div>
                                        <div class="col-12">
                                            <textarea-simple
                                                label="Description"
                                                name="description"
                                                placeholder="Enter description"
                                                v-model="formContacts.description"
                                                :hint="formContacts.errors.description"
                                            ></textarea-simple>
                                        </div>
                                    </div>
                                </form>
                                <!-- Form End -->
                            </template>
                            <template v-slot:modal-footer>
                                <button type="button" class="btn btn-primary" @click="submitFormContacts">Save</button>
                            </template>
                        </modal>
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:footer-buttons v-if="actions.includes('Delete')||actions.includes('Update')">
            <button type="button"
                    v-if="actions.includes('Delete')"
                    class="btn btn-outline-danger mb-3 mb-sm-0" @click="deleteItem">Delete consolidator</button>
            <button type="button"
                    v-if="actions.includes('Update')"
                    class="btn btn-alt-secondary mb-3 mb-sm-0 ms-sm-3" @click="submitForm(true)">Save and exit</button>
            <button type="button"
                    v-if="actions.includes('Update')"
                    class="btn btn-primary ms-sm-3" @click="submitForm(false)">Save</button>
        </template>

    </Layout>

</template>

<style>

</style>
